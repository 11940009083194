@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");

.hero-image img {
  width: 100%;
  max-width: fit-content;
}

.hero-image {
  /* Lower than header's z-index */
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
}

.hero {
  width: 100%;
}

.mission {
  display: flex;

  margin-top: 4rem;
}

.coming-soon {
  text-align: center;
  margin-top: 20px;
  height: 21vh;
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
  font-size: 1.2em; 
  font-weight: bold;
  padding: 20px; 
  border-radius: 10px; 
  box-shadow: 1px 8px 16px 0px rgba(0,0,0,0.2); 
}


.mission-content {
  display: flex;
  align-items: center;
  margin: 0 10%;
  max-width: 80%;
}

.mission-image {
  width: 50%;
  margin-right: 2rem;
  border-radius: 40px;
}
.mission-text {
  flex-grow: 1;
  font-family: "DM Sans", sans-serif;
}
.mission-text h2 {
  margin-bottom: 1rem;
}
.mission-text p {
  font-family: "Quicksand", sans-serif;
  font-weight: 400;
  font-size: 20px;
}

.stories {
  padding: 3rem 0;
  margin: 0 10%;
  text-align: center;
}

.story-images {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
}

.story-image {
  width: 280px;
  height: 280px;
  border-radius: 50%;
  background-color: #bda5a3;
}

/* Other Home styles remain the same */
.stories-container h2 {
  font-family: "Quicksand", sans-serif;
  font-weight: 400;
  font-size: 20px;
}

.stories-container h1 {
  font-family: "DM Sans", sans-serif;
  font-weight: bolder;
  font-size: 30px;
}


.get-involved-image {
  width: 300px;
  margin-right: 150px;
  border-radius: 20px;
}

.get-involved {
  padding: 2rem 0;
  margin: 0;
  background-color: #d9d9d9;
}

.get-involved h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.get-involved-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.get-involved-text {
  width: 50%;
  margin-left: 150px;
}

@media (max-width: 767px) {
  .mission {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 4rem;
  }

  .mission-content {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    margin: 0 10%;
    max-width: 80%;
  }

  .mission-image {
    width: 100%;
    margin: 0px;
    border-radius: 40px;
  }

  .mission-text {
    text-align: center;
  }

  .coming-soon {
    text-align: center;
    margin-top: 20px;
    height: 25vh;
    width: 65%;
    margin-left: 12%;
    margin-right: 25%;
    font-size: 1em; 
    font-weight: bold; 
    padding: 20px; 
    border-radius: 10px;
    box-shadow: 1px 8px 16px 0px rgba(0,0,0,0.2);
  }

  .story-images {
    display: grid;
    justify-content: center;
    gap: 2rem;
    margin-top: 2rem;
  }

  .get-involved-text {
    display: inline-block;
    margin-left: 0;
    text-align: center;
  }

  .get-involved-content {
    display: block;
    grid-template-columns: 1fr;
    justify-content: center;
    text-align: center;
    
  }

  .get-involved-image {
    width: 300px;
    margin: 0;
    border-radius: 20px;
  }

  .hero-image {
    /* Lower than header's z-index */
    margin-top: 76px;
    top: 0;
    left: 0;
    width: 100%;
  }
}
