@import url('https://fonts.googleapis.com/css2?family=Cardo&family=DM+Sans&display=swap');


.gallery-container{
    margin-top:20vh;
}

.gallery-container h1{
    font-family:'Cardo', Courier, monospace;
    padding-left:2rem;
    border-bottom: solid black 5px;
    width:10%;
}

@media (max-width: 767px){
    .gallery-container {
        margin-top: 5rem;
    }
}
