.about-us {
    text-align: center;
    padding-top:1rem;
    padding-bottom:1.5rem;
    margin-top: 2rem;
    background-color: #D1CECF;
  }
  
  .about-us h1 {
    
      font-family: 'DM Sans', sans-serif;
      font-weight: bolder;
      font-size: 36px;
    }
  
  .about-us h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .about-us p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1rem;
  }
  
  .learn-more {
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: #B35236;
    color: #ffffff;
    font-weight: bold;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s;
    margin-bottom: 2rem;
  }
  
  .learn-more:hover {
    background-color: #ABCF61;
  }
  
  .about-images {
    display: flex;
    justify-content: space-between;
    margin: 0 10%;
   
  }
  
  .about-images img {
    width: 45%;
    border-radius: 40px;
  }
  

  .about-hero{
    height:100vh;
    width:100%;
    background-image: url("../../assets/ellenyAboutus.png");
    background-size:cover;
}

.about-head{
    padding-top: 15%;
    margin-left: 8%;
    text-align: left;
}

.about-head h1{
    margin-bottom: 24px;
    max-width: 100%;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-stretch: normal;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    margin-bottom: 12px;
    font-size: 36px;
    line-height: 1.17;
    max-width: 264px;
}

.about-tag{
    color: rgb(255, 255, 255);
    font-size: 18px;
    line-height: 1.67;
    width: 30%;
}

.content-section{
    width: 100%;
    max-width: 640px;
    display:flex;
    flex-direction: column;
    padding-left:30%;
    padding-top:100px;
    padding-bottom: 100px;
}

.section1-head{
    
    font-size: 36px;
    line-height: 1.17;
    font-family: Montserrat, sans-serif;
    font-weight: 700;
    letter-spacing: 1px;
    color: rgb(29, 37, 44);
    text-transform: uppercase;
    
    text-align: center;
}
.section1-head h3{
    font-size: 48px;
    line-height: 1.08;
}

.section1-body{
    transform: translateY(0px);
    opacity: 1;
    text-align: left;
    font-size: 18px;
    line-height: 1.67;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: rgb(29, 37, 44);
}

@media (max-width: 767px){

  .about-us {
    text-align: center;
    padding: 1rem 1rem 1rem 1.5rem;
    
    margin-top: 2rem;
    
    
  }
  

  .about-images {
    display: grid;
    gap:2rem;
    justify-content: center;
    margin: 0 10%;
   
  }

  .about-images img {
    width: 100%;
   
  }

  .about-tag{
    color: rgb(255, 255, 255);
    font-size: 18px;
    line-height: 1.67;
    width: auto;
}

.about-hero{
  margin-top: 76px;
  height:50vh;
  background-size:auto auto;
  object-fit: cover;
  padding-bottom:1rem;
}

}
