.footer {
    background-color: white;
    margin-top: 30px;
  }
  
  .container {
    max-width: auto;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 10%;
  }
  
  .footer-logo {
    width: 30%;
    height: auto;
  }
  
  .footer-nav {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex: 1;
    padding: 0;
    width: 300px;
    border-right: 2px solid black;
  }
  
  .footer-link {
    text-decoration: none;
    color: inherit;
    padding: 0;
    margin: 0 30px 0 0;
    margin-bottom: 0.5rem;
  }
  
  .footer-link.active {
    text-decoration: underline;
  }
  
  .donate-footer {
    display: block;
    position: absolute;
    padding: 0.5rem 1rem;
    margin-top: -50px;
    background-color: #d0b25e;
    color: #ffffff;
    font-weight: bold;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s;
    margin-left: 0.4rem;
  }

  .social-links {
    display: flex;
    margin-top: 50px;
    flex-direction: row;
    align-items: flex-start;
    margin-left: 20px;

  }
  
  .social-link {
    text-decoration: none;
    color: inherit;
    margin: 3px;
    align-items: start;
    margin-bottom: 0.5rem;
  }

  .facebook-link {
    width: 40px;
    display: flex;
    flex-direction: row;
  }

  .instagram-link {
    width: 40px;
    display: flex;
    flex-direction: row;
  }
  
  .footer-copyright {
    margin-top: rem;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
  }


  @media (max-width: 767px){
    
    .footer-content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 60% 40%;
      column-gap:1rem;
      flex-wrap: wrap;
      margin: 0 ;
    }
   
    .footer-logo{
      grid-area: 1/1/2/2;
      width:100%;
    }

    .footer-nav{
      grid-area: 1/2/3/3;
      
      display: flex;
    flex-direction: column;
   
    
    padding: 0;
    width: auto;
    border-left: 2px solid black;
    border-right: none;
    }

    .social-links{
      grid-area: 2/1/3/2;
    }

  }